import { MaterialSymbolsAssignmentRounded } from '@/assets/icons/funds/MaterialSymbolsAssignmentRounded';
import { BaseFlex } from '@/components/base/baseFlex';
import BaseTopNav from '@/components/base/baseTopNav';
import { CompletedOrderOrderProcessEnum } from '@/enums/businessEnum';
import { AccountTransfer } from '@/pages/funds/transfer/components/AccountTransfer';
import { history } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import PageEnum from '../../../enums/pageEnum';

export default function TransferView() {
  const { t } = useTranslation();

  return (
    <div>
      <BaseTopNav
        title={t('划转')}
        rightNode={
          // null
          <MaterialSymbolsAssignmentRounded
            onClick={() => {
              history.push(
                PageEnum.ORDER_HISTORY +
                `?type=${CompletedOrderOrderProcessEnum.TRANSFER}`,
              );
            }}
            className="text-backContrastColor cursor-pointer p-4 box-content"
          />
        }
      />
      {/* 骨架屏 */}
      <div className="px-4 mt-6">

        {/* 划转账户 */}
        <BaseFlex align="center" justify="start" vertical >
          <AccountTransfer />
        </BaseFlex>
      </div>
    </div>
  );
}
