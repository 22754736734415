import type { SVGProps } from 'react';

export function ContractWalletIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M0 2C0 0.89543 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V20.7007C20 22.2025 18.4064 23.1686 17.0749 22.4738L10.9251 19.2653C10.3455 18.9628 9.65455 18.9628 9.07487 19.2653L2.92513 22.4738C1.5936 23.1686 0 22.2025 0 20.7007V2ZM5 6C5 5.44772 5.44772 5 6 5H14C14.5523 5 15 5.44772 15 6C15 6.55228 14.5523 7 14 7H6C5.44772 7 5 6.55228 5 6ZM6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H14C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11H6Z"
            fill="currentColor" />
    </svg>
  );
}
