import React from 'react';
import type { SVGProps } from 'react';

export function TransferButtonIcon(props: SVGProps<SVGSVGElement>) {
  return (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.5176 19.6845C13.5224 19.7459 13.5437 19.805 13.5793 19.8553C13.6149 19.9057 13.6634 19.9455 13.7197 19.9705C13.7761 19.9955 13.8382 20.0049 13.8994 19.9976C13.9606 19.9903 14.0187 19.9665 14.0676 19.9289L19.702 13.5122C19.8487 13.28 19.7753 12.9622 19.4576 12.9622L15.8887 12.9622L15.8887 2.88224C15.8939 2.77812 15.8773 2.67407 15.8398 2.57678C15.8024 2.4795 15.745 2.39114 15.6712 2.31743C15.5975 2.24372 15.5092 2.18628 15.4119 2.14884C15.3146 2.11139 15.2106 2.09476 15.1064 2.10002L14.2998 2.10002C14.1957 2.09476 14.0916 2.11139 13.9943 2.14884C13.897 2.18629 13.8087 2.24372 13.735 2.31743C13.6613 2.39114 13.6038 2.4795 13.5664 2.57678C13.5289 2.67407 13.5123 2.77812 13.5176 2.88224L13.5176 19.6845Z"
        fill="currentColor" />
      <path
        d="M8.42076 2.19285C8.45635 2.2432 8.47767 2.30223 8.48248 2.3637L8.48248 19.2148C8.48774 19.3189 8.47111 19.423 8.43366 19.5203C8.39621 19.6176 8.33878 19.7059 8.26506 19.7796C8.19135 19.8533 8.103 19.9108 8.00572 19.9482C7.90843 19.9857 7.80437 20.0023 7.70026 19.997L6.91804 19.997C6.8075 20.0069 6.69614 19.992 6.5921 19.9534C6.48806 19.9147 6.39396 19.8533 6.31666 19.7737C6.23936 19.6941 6.18082 19.5982 6.1453 19.493C6.10979 19.3879 6.0982 19.2761 6.11137 19.1659L6.11137 9.08592L2.54248 9.08592C2.2247 9.08592 2.15137 8.76815 2.29804 8.53592L7.93248 2.11926C7.98133 2.08164 8.03944 2.05791 8.10066 2.05058C8.16188 2.04326 8.22395 2.05262 8.28029 2.07766C8.33664 2.1027 8.38517 2.1425 8.42076 2.19285Z"
        fill="currentColor" />
    </svg>
  );
}