import type { SVGProps } from 'react';

export function SpotWalletIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.2426 7.99997L8 12.2426L12.2426 16.4853L16.4853 12.2426L12.2426 7.99997Z"
            fill="currentColor" />
    </svg>

  );
}
