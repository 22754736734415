import { IconParkSolidCorrect } from '@/assets/icons/comm/IconParkSolidCorrect';
import BaseModal from '@/components/base/baseModal';
import { WalletTypeEnum } from '@/enums/businessEnum';
import { ScrollShadow } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { SpotWalletIcon } from '@/assets/icons/comm/SpotWalletIcon';
import { ContractWalletIcon } from '@/assets/icons/comm/ContractWalletIcon';
import { useMemo } from 'react';
import { useModel } from '@@/exports';
import RenderUtil from '@/utils/RenderUtil';

type AccountSelectPopupProps = any;

export function AccountSelectPopup(props: AccountSelectPopupProps | any) {
  const { onSelect, accountValue, accountType, contractAvailableFunds,spotUSDT,...reset } = props;

  const { t } = useTranslation();


  const options = useMemo(() => {
    return [
      {
        label: t('现货钱包'),
        value: WalletTypeEnum.COIN,
        icon:SpotWalletIcon,
        available: spotUSDT,
      },
      {
        label: t('合约钱包'),
        value: WalletTypeEnum.CONTRACT,
        icon:ContractWalletIcon,
        available: contractAvailableFunds,
      },
    ]
  },[spotUSDT,contractAvailableFunds]);



  return (
    <BaseModal
      {...reset}
      classNames={{ title: 'text-[17px] font-semibold' }}
      title={t('更换钱包')}
    >
      <ScrollShadow hideScrollBar className="max-h-[60vh] sm:h-[40vh] pb-6 h-fit">
        {/*加载银行卡列表，如果处于加载状态则显示加载动画*/}
        <div className="mt-2 px-3 flex flex-col gap-4">
          {/*遍历银行卡列表，每个银行卡信息展示为一个可点击的卡片*/}
          {options &&
            +options?.length !== 0 &&
            options?.map((item: any) => (
              <div
                className={`flex items-center px-4 justify-between  border-[1px] border-borderColor  text-[16px] text-foreground rounded-md py-4 `}
                key={item.value}
                onClick={() => {
                  onSelect(item.value);
                }}
              >
                <div className="flex items-center gap-y-2 gap-x-[22px]">
                  <item.icon class="text-auxiliaryTextColor" />
                  <div className=''>
                    <div className="mb-[2px] text-backContrastColor">
                      {item.label}
                    </div>
                    <div className="text-xs text-auxiliaryTextColor">
                      {t("可用:{{amount}} USDT",{
                        amount: RenderUtil.FormatAmount(item.available,2)
                      })}
                    </div>
                  </div>
                </div>
                {accountType === 'from' &&
                  accountValue['from'] === item.value && (
                    <IconParkSolidCorrect className="w--[16px] h-[16px] text-primary" />
                  )}
                {accountType === 'to' && accountValue['to'] === item.value && (
                  <IconParkSolidCorrect className="w--[16px] h-[16px] text-primary" />
                )}
              </div>
            ))}
        </div>
      </ScrollShadow>
      {/* <div
        onClick={reset?.onClose}
        className={`text-center  border-t-[1px] border-[#F2F3F5]  text-[16px] text-foreground mb-5 py-4 `}
      >
        <span>{t('取消')}</span>
      </div> */}
    </BaseModal>
  );
}
