import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import { MingcuteTransferFill } from '@/assets/icons/funds/MingcuteTransferFill';
import { BaseFlex } from '@/components/base/baseFlex';
import FeaturesNumberInput from '@/components/features/featuresNumberInput';
import {
  getAllCurrenciesApi,
  getAvailableBalanceApi,
  spotAndContractCurrenciesApi,
  transferApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useModel } from '@@/exports';
import { Button, Image } from '@nextui-org/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';
import { AccountSelectPopup } from './AccountSelectPopup';

import { TeenyiconsDownSolid } from '@/assets/icons/comm/TeenyiconsDownSolid';
import BaseSkeleton from '@/components/base/baseSkeleton';
import { WalletTypeEnum } from '@/enums/businessEnum';
import RenderUtil from '@/utils/RenderUtil';
import { toast } from '@/layouts/components/ToastMessage';
import { useMount, useUpdateEffect } from 'ahooks';
import { TransferButtonIcon } from '@/assets/icons/funds/TransferButtonIcon';
import { PhCaretRight } from '@/assets/icons/comm/PhCaretRight';

export enum CurrencyOperateEnum {
  //划转
  TRANSFER = 'transfer',
  //现货充值
  SPOT_RECHARGE = 'spot_recharge',
  //合约充值
  CONTRACT_RECHARGE = 'contract_recharge',
}

/**
 * 划转
 */

export function AccountTransfer() {
  const { isPc } = useModel('system');
  const { t } = useTranslation();
  const [amount, setAmount] = useState<any>(0);
  // 股票钱包=3， 虚拟币钱包=2， 基金钱包=4
  const [accountValue, setAccountValue] = useState<any>({
    from: WalletTypeEnum.COIN,
    to: WalletTypeEnum.CONTRACT,
  });

  const [toggle, setToggle] = useState(false);
  const fromWalletType = useMemo(()=>{
    return  toggle ? accountValue.to : accountValue.from
  },[accountValue,toggle])
  // 显示的币种
  const [coin, setCoin] = useState<any>({
    from: '',
    to: '',
  });
  // 选择账号
  const [openSelected, setOpenSelected] = useState(false);
  const { x } = useSpring({
    to: { x: toggle ? 1 : 0 },
    config: { tension: 200, friction: 20 },
  });
  // 现货及合约可用余额USDT
  const { coinsFunds, renderFunds, contractAvailableFunds, refreshWallet } = useModel('user');
  const renderList = useMemo(() => {
    return coinsFunds?.walletList?.map((item: any) => {
      // 查找socket推送的币币列表
      const findItem = renderFunds?.coin?.walletList?.find?.(
        (fundsItem: any) => {
          return fundsItem?.name === item?.name;
        },
      );
      return {
        ...item,
        availableBalance: findItem?.ab ?? item?.availableBalance,
        convertUsd: findItem?.abu ?? item?.convertUsd,
        freezeBalance: findItem?.fb ?? item?.freezeBalance,
      };
    });
  }, [coinsFunds, renderFunds]);
  const spotUSDT = useMemo(() => {
    return renderList?.find((item: any) => {
      return item?.name === 'USDT';
    })?.availableBalance;
  }, [renderList]);

  // 当前可用资金，根据账户判断
  const availableFunds = useMemo(() => {
    if(fromWalletType === WalletTypeEnum.COIN){
      return spotUSDT;
    }
    if(fromWalletType === WalletTypeEnum.CONTRACT){
      return contractAvailableFunds;
    }

  },[fromWalletType,spotUSDT, contractAvailableFunds])

  // 账户类型
  const [accountType, setAccountType] = useState<any>('from');

  const { runAsync: transferReq,loading } = useReq(transferApi, {
    manual: true,
    loadingDefault:false,
    onSuccess() {
      toast.success(t('划转成功'));
      setAmount(0);
      refreshWallet();
    },
  });
  const {
    data: allCurrencies = [] as any,
    runAsync: getAllCurrenciesReq,
    loading: loadingAllCurrencies,
  } = useReq(getAllCurrenciesApi, {
    manual: true,
    onSuccess(data) {
      const findUSDT = data.find((item: any) => item.name === 'USDT');
      setCoin({
        from: findUSDT,
        to: findUSDT,
      });
    },
  });

  useMount(() => {
    getAllCurrenciesReq();
  });


  const transformX = useMemo(() => {
    /**
     * 适配 pc和手机
     */
    return 99;
  }, [toggle, isPc]);

  return (
    <>
      {!loadingAllCurrencies && (
        <div className="min-h-[calc(100vh-200px)] relative w-full text-sm">
          <div className="bg-backgroundAuxiliaryColor p-2 pl-4 rounded-md">
            {/* 钱包 */}
            <div className="flex items-center justify-between ">
              <div className=" flex-1">
                <div className="flex items-center">
                  <div className="text-auxiliaryTextColor flex-shrink-0 w-[40px]">{t('从')}</div>
                  <animated.div
                    style={{
                      width: '100%',
                      transform: x.to((x) => `translateY(${x * transformX}%)`),
                    }}
                  >
                    <div className="flex-1">
                      <div
                        className="flex items-center bg-backgroundAuxiliaryColor  rounded-xl px-[12px]"
                        onClick={() => {
                          setAccountType('from');
                          setOpenSelected(true);
                        }}
                      >
                        <div className="text-titleColor h-[48px] rounded-xl flex justify-between flex-1 items-center">
                          {accountValue.from === WalletTypeEnum.COIN && (
                            <span>{t('现货钱包')}</span>
                          )}
                          {accountValue.from === WalletTypeEnum.CONTRACT && (
                            <span>{t('合约钱包')}</span>
                          )}

                          <PhCaretRight className="mr-2 text-[12px] text-auxiliaryTextColor" />
                        </div>
                      </div>
                    </div>
                  </animated.div>
                </div>
                <div className="flex items-center">
                  <div className="text-auxiliaryTextColor flex-shrink-0 w-[40px]">{t('到')}</div>
                  <animated.div
                    style={{
                      width: '100%',
                      transform: x.to((x) => `translateY(${-x * transformX}%)`),
                    }}
                  >
                    <div className="flex-1">
                      <div
                        className="flex items-center  h-[48px] flex-1 bg-backgroundAuxiliaryColor  rounded-xl  px-[12px]"
                        onClick={() => {
                          setAccountType('to');
                          setOpenSelected(true);
                        }}
                      >
                        <div className="text-titleColor  py-2 rounded-xl pl-0 flex flex-1 justify-between items-center">
                          {accountValue.to === WalletTypeEnum.COIN && (
                            <span>{t('现货钱包')}</span>
                          )}
                          {accountValue.to === WalletTypeEnum.CONTRACT && (
                            <span>{t('合约钱包')}</span>
                          )}

                          <PhCaretRight className="mr-2 text-[12px] text-auxiliaryTextColor" />
                        </div>
                      </div>
                    </div>
                  </animated.div>
                </div>
              </div>
              <Button
                isIconOnly
                color="primary"
                variant="faded"
                aria-label="Take a photo"
                className="border-0 flex-shrink-0 rounded-full mx-[12px]"
                onClick={() => {
                  setToggle((prevState) => !prevState);
                }}
              >
                <TransferButtonIcon
                  className={`transition text-backContrastColor  w-[20px] h-[20px]   text-2xl`}
                />
              </Button>
            </div>
          </div>

          {/* ustd */}
          <div className="mt-8">
            <span className="text-auxiliaryTextColor">{t('币种')}</span>
            <div className="bg-backgroundAuxiliaryColor p-2 rounded-md mt-1.5">
              <BaseFlex align="center" justify="between" flex={1}>
                <div
                  className="flex-1  h-[48px] px-[12px] py-1 flex bg-backgroundAuxiliaryColor rounded-xl justify-between items-center "
                >
                  <div className="flex">
                    <Image
                      className="w-[26px] h-[26px] rounded-full object-cover"
                      src={toggle ? coin.to?.icon : coin.from?.icon}
                    />
                    <BaseFlex align="center" justify="start" flex={1}>
                    <span className="ml-[4px] font-bold text-base">
                      {toggle ? coin.to?.alias : coin.from?.alias ?? ''}
                    </span>
                      <span className="text-sm ml-0.5 text-auxiliaryTextColor"></span>
                    </BaseFlex>
                  </div>
                  {/* usd右边的箭头 */}
                  {/* <TeenyiconsDownSolid className="mr-2 text-[9px] text-auxiliaryTextColor" /> */}
                </div>
              </BaseFlex>
            </div>
          </div>


          {/* 选择钱包 */}
          <AccountSelectPopup
            isOpen={openSelected}
            onClose={() => {
              setOpenSelected(false);
            }}
            spotUSDT={spotUSDT}
            contractAvailableFunds={contractAvailableFunds}
            accountValue={accountValue}
            accountType={accountType}
            onSelect={(v: number) => {
              // 钱包回调
              if (accountType === 'from') {
                setAccountValue((old: any) => ({ ...old, from: v }));
              } else {
                setAccountValue((old: any) => ({ ...old, to: v }));
              }
              setOpenSelected(false);
            }}
          />

          <BaseFlex align="center" justify="start" flex={1} className="pt-2">
            <span className="text-errorColor">
              {/*{t('当前币种无可划转资产，请选择其他币种')}*/}
            </span>
          </BaseFlex>
          <BaseFlex align="center" justify="start" flex={1} className="mt-4">
            <span className="text-auxiliaryTextColor">{t('金额')}</span>
          </BaseFlex>

          <BaseFlex align="center" justify="start" flex={1} className="mt-1.5">
            <FeaturesNumberInput
              size="lg"
              radius="sm"
              value={amount as any}
              onChange={(value: any) => {
                setAmount(value);
              }}
              className=" h-[48px] border-0"
              classNames={{
                input:
                  'text-titleColor  h-[48px] bg-backgroundAuxiliaryColor  w-full',
                inputWrapper: 'bg-backgroundAuxiliaryColor h-14 border-0',
              }}
              placeholder={t('请输入数量')}
              endContent={
                <div
                  onClick={() => {
                    setAmount(availableFunds);
                  }}
                  className="pr-2  flex-shrink-0 text-primary font-bold"
                >
                  {t('最大')}
                </div>
              }
            />
          </BaseFlex>

          <BaseFlex align="center" justify="start" flex={1} className="mt-1.5">
            <span className="text-auxiliaryTextColor text-xs">{t('可用')}</span>
            <span className="ml-2 text-auxiliaryTextColor text-xs flex items-center">
                {`${RenderUtil.FormatAmount(availableFunds, 2)} ${coin.from?.alias ?? ''}`}
              </span>
          </BaseFlex>

          <BaseFlex align="center" justify="center" flex={1} className="py-6">
            <Button
              spinner={<LoadingSvg />}
              isLoading={loading}
              onClick={async () => {
                if (!amount) return toast.error(t('请输入需要划转的金额'));
                const to_add_data = {
                  amount: amount,
                  fromWalletType: toggle ? accountValue.to : accountValue.from,
                  toWalletType: toggle ? accountValue.from : accountValue.to,
                  fromCoinId: coin.from.id,
                  toCoinId: coin.to.id,
                };
                await transferReq(to_add_data);
                refreshWallet();
                setAmount('');
              }}
              className="mainColorButton !rounded-md  h-[48px] !py-0 mt-3"
            >
              {t('确认划转')}
            </Button>
          </BaseFlex>
        </div>
      )}
      <div className="w-full">
        <BaseSkeleton isLoaded={loadingAllCurrencies}>
          <div className="flex flex-col justify-between  mb-2">
            <div className="mb-2 flex justify-between bg-backgroundAuxiliaryColor w-1/3">
              <span className="text-xs font-bold text-auxiliaryTextColor">
                &nbsp;
              </span>
            </div>
            <div className=" bg-backgroundAuxiliaryColor h-[40px] rounded-md "></div>
          </div>
          <div className="mb-4 flex justify-between bg-backgroundAuxiliaryColor w-1/3">
            <span className="text-xs font-bold text-auxiliaryTextColor">
              &nbsp;
            </span>
          </div>
          <div className="flex flex-col justify-between  mb-3">
            <div className="mb-2 flex justify-between bg-backgroundAuxiliaryColor  w-1/3">
              <span className="text-xs font-bold text-auxiliaryTextColor">
                &nbsp;
              </span>
            </div>
            <div className=" bg-backgroundAuxiliaryColor h-[40px] rounded-md "></div>
          </div>

          <div className="flex flex-col justify-between h-[20px] mb-3">
            <div className=" bg-backgroundAuxiliaryColor h-full  rounded-md "></div>
          </div>
          <div className="flex flex-col justify-between  mb-3">
            <div className=" bg-backgroundAuxiliaryColor h-[40px] rounded-md "></div>
          </div>
          <div className="flex flex-col justify-between h-[20px] mb-4">
            <div className=" bg-backgroundAuxiliaryColor h-full  rounded-md "></div>
          </div>
          <div className="flex flex-col justify-between h-[60px] mb-3">
            <div className=" bg-backgroundAuxiliaryColor h-full  rounded-md "></div>
          </div>
        </BaseSkeleton>
      </div>
    </>
  );
}
